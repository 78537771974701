export const dataMap = [
    {
        id:1,
        titolo:"Come parteciperai all'evento",
        sezioni:[
            {
                titolo: 'Informazioni Generali',
                infoTop: [],
                infoBottom: [],
                campi: [
                    {
                        id: 'partecipo',
                        title: 'Partecipo*',
                        type: 'select',
                        compiled: false,
                        required: true,
                        values: ['In presenza', 'In streaming', 'Non parteciperò']
                    },
                ]
            }
        ]
    },
    {
        id:2,
        titolo:'Completa la tua registrazione qui sotto.',
        sezioni:[
            {
                titolo:'Informazioni Generali',
                infoTop:[],
                infoBottom:[],
                campi: [
                    {id:'nome', title:'Nome', type:'text', compiled: true, required:true},
                    {id:'cognome', title:'Cognome', type:'text', compiled: true, required:true},
                    {id:'indirizzo', title:'Indirizzo', placeholder:'Ti preghiamo di inserire il tuo abituale domicilio' , type:'text', compiled: false, required:true},
                    {id:'cap', title:'CAP', type:'number', compiled: false, required:true},
                    {id:'provincia', title:'Provincia', type:'text', compiled: false, required:true},
                    {id:'citta', title:'Città', type:'text', compiled: false, required:true},
                    {id:'paese', title:'Paese', type:'text', compiled: false, required:true},
                    {id:'luogoNascita', title:'Luogo di nascita', type:'text', compiled: false, required:true},
                    {id:'dataNascita', title:'Data di nascita', type:'date', compiled: false, required:true},
                ]
            },
            {
                titolo:'Informazioni di contatto',
                infoTop:[],
                infoBottom:[],
                campi: [
                    {id:'email', title:'Email', type:'email', compiled: true, required:true},
                    {id:'cellulare', title:'Cellulare', type:'number', compiled: false, required:true},
                    {id:'notaCellulare', title:'Nota cellulare*' , type:'select', compiled: false, required:true, values: ['Privato','Aziendale']},
                ]
            },
            {
                titolo:'Informazioni lavorative',
                infoTop:[],
                infoBottom:[],
                campi: [
                    {id:'ragioneSociale', title:'Ragione sociale*', type:'select', compiled: false, required:true,
                        values: [
                            "Privato",
                            "Pubblico",
                        ]
                    }
                ]
            }
        ],
    },
    {
        id:3,
        titolo:'Completa la tua registrazione qui sotto.',
        sezioni:[
            {
                titolo:'Pernottamenti:',
                infoTop:['Se hai diritto a una sistemazione in albergo, ma preferisci fare rientro al tuo domicilio o organizzare autonomamente il tuo pernottamento a Milano, seleziona la voce "non usufruirò del pernottamento" e prosegui con la registrazione. Per questioni organizzative, ti facciamo presente che la scelta che indicherai non potrà essere modificata in un secondo momento.'],
                infoBottom:[],
                campi: [
                    {id:'pernottamento', title:'Pernottamento*', type:'select', compiled: false, required:true, values:['Usufruirò del pernottamento','Non usufruirò del pernottamento']}
                ]
            }
        ],
    },
    {
        id:'3A',
        titolo:'Completa la tua registrazione qui sotto.',
        sezioni:[
            {
                titolo:'Documento',
                infoTop:[],
                infoBottom:[],
                campi: [
                    {id:'tipoDocumento', title:'Tipologia di documento*', type:'select', compiled: false, required:true, values: ["Carta d'identità","Patente",'Passaporto','Altro']},
                    {id:'numeroDocumento', title:'Numero del documento', type:'text', compiled: false, required:true},
                    {id:'rilasciatoIl', title:'Rilasciato il (quando)', type:'date', compiled: false, required:true},
                    {id:'rilasciatoDa', title:'Rilasciato da (dove)', type:'text', compiled: false, required:true},
                    {id:'scadenza', title:'Data di scadenza', type:'date', compiled: false, required:true},
                ]
            }
        ],
    },
    {
        id:'3B',
        titolo:'Completa la tua registrazione qui sotto.',
        sezioni:[
            {
                titolo:'Sistemazione',
                infoTop:['Dal momento che alloggerai in camera TWIN, per organizzare al meglio la tua permanenza, ti chiediamo di indicarci con chi desideri condividere la stanza e di chiedere alla persona individuata di specificare il tuo nome in fase di registrazione. Se non segnalerai alcun nominativo o il collega da te indicato risultasse già abbinato con qualcun altro, la persona con cui condividerai la stanza sarà scelta direttamente dalla Segreteria Organizzativa.',"NB: A meno di comprovati legami parentali, non sarà possibile pernottare con una persona di sesso opposto.","La camera singola sarà concessa solo a seguito di giustificati e comprovati motivi di carattere medico. È necessario inviare la richiesta alla Segreteria Organizzativa, corredandola di tutta la documentazione medica."],
                infoBottom:[],
                campi: [
                    {id:'nomeCompagno', title:'Nome compagno di stanza', type:'text', compiled: false, required:false},
                    {id:'cognomeCompagno', title:'Cognome compagno di stanza', type:'text', compiled: false, required:false},
                ]
            }
        ],
    },
    {
        id:4,
        titolo:'Completa la tua registrazione qui sotto.',
        sezioni:[
            {
                titolo:'Dettagli logistici',
                infoTop:["Per facilitare l'organizzazione logistica, ti chiediamo di organizzarti in autonomia per raggiungere Milano/la location.","Se hai necessità di prenotare treno o aereo, puoi fare riferimento all'agenzia viaggi Gattinoni (adecco@gattinoni.it). Ti segnaliamo che eventuali spese per biglietti di treni o aerei acquistati in autonomia NON saranno rimborsate.","Se decidi di viaggiare in auto, ti raccomandiamo di organizzarti in car sharing utilizzando l'auto aziendale, possibilmente coordinandoti con i colleghi anche di filiali e/o unità organizzative diverse dalla tua.","Le spese sostenute per i trasferimenti con auto NON aziendali NON saranno rimborsate."],
                infoBottom:["Ti chiederemo in un secondo momento (entro fine novembre) di completare alcuni dettagli logistici circa i tuoi spostamenti, ma ci piacerebbe sapere sin d'ora come raggiungerai Milano/la location dell'evento."],
                campi: [
                    {id:'mezzoDiTrasporto', title:'Mezzo di trasporto*', type:'select', compiled: false, required:true,
                        values: [
                            "In aereo",
                            "In treno",
                            "Con la mia auto aziendale",
                            "Con l'auto aziendale di un collega",
                            "Con il mio mezzo di trasporto privato",
                            "Con i mezzi pubblici / la metro",
                        ]
                    },
                ]
            }
        ],
    },
    {
        id:5,
        titolo: "Privacy",
        sezioni: [
            {
                titolo: 'Privacy',
                infoTop:[],
                infoBottom:[],
                campi: [
                    {id:'privacy', title:'Preso atto dell’informativa privacy del titolare del trattamento Piano B Srl, presto il consenso al trattamento dei miei dati personali – eventualmente anche particolari -  per gli scopi e con le modalità indicate.*',
                        type:'checkbox', compiled: false, required:true},
                ]
            }
        ]
    }
]
