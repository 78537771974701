import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    Grid, Stack, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {ContentSezione} from "./ContentSezione";
import {Info} from "@mui/icons-material";

function createData(voce, rimborso){
    return {voce, rimborso}
}

const rows = [
    createData('Spese per mezzi di trasporto NON prenotati tramite agenzia viaggi aziendale','NO'),
    createData('Carburante auto AZIENDALE da sede di lavoro alla stazione/aeroporto per prendere treni o aerei prenotati tramite agenzia viaggi aziendale','Fuel Card'),
    createData('Carburante auto AZIENDALE da sede di lavoro a Milano','Fuel Card'),
    createData('Carburante auto NON AZIENDALE da sede di lavoro alla stazione/aeroporto per prendere treni o aerei prenotati tramite agenzia viaggi aziendale','NO'),
    createData('Carburante auto NON AZIENDALE da sede di lavoro a Milano','NO'),
    createData('Casello autostradale auto AZIENDALE da sede di lavoro alla stazione/aeroporto per prendere treni o aerei prenotati tramite agenzia viaggi aziendale(con ricevuta di pedaggio)','SI'),
    createData('Casello autostradale auto AZIENDALE da sede di lavoro a Milano (con ricevuta di pedaggio)','SI'),
    createData('Casello autostradale auto NON AZIENDALE da sede di lavoro alla stazione/aeroporto per prendere treni o aerei prenotati tramite agenzia viaggi aziendale','NO'),
    createData('Casello autostradale auto NON AZIENDALE da sede di lavoro a Milano','NO'),
    createData('Parcheggio auto AZIENDALE alla stazione/aeroporto per prendere treni o aerei prenotati tramite agenzia viaggi aziendale','SI'),
    createData('Parcheggio auto AZIENDALE presso hotel a Milano per la notte del 20/12','SI'),
    createData('Parcheggio auto NON AZIENDALE per prendere treni o aerei prenotati tramite agenzia viaggi aziendale','NO'),
    createData('Parcheggio auto NON AZIENDALE presso hotel a Milano per la notte del 20/12','NO'),
    createData('Parcheggio auto NON AZIENDALE presso la location del KO - SOLO per i dipendenti che non usufruiranno del pernottamento (distanza <30 km)','SI'),
    createData('Parcheggio auto AZIENDALE presso la location del KO','SI'),
    createData('Taxi per spostamenti in Milano','NO'),
    createData('Extra nelle due giornate del Kick Off non incluse in questa tabella','NO'),
    createData('Extra in hotel','NO')
]

export const Sezione = ({contenuto, dati, handleSetDati, step, dirittoPernottamento, recap}) => {
    const [open, setOpen] = useState(false)
    return (
        <Box sx={{pb:1}}>
            {/*<Box>
                {contenuto.infoTop.length > 0 && contenuto.infoTop.map(i => <Typography sx={{py: 0.5}} variant={"body2"}
                                                                                        key={i}>{i}</Typography>)}
            </Box>*/}
            <Dialog open={open} maxWidth={"lg"} onClose={() => setOpen(!open)}>
                <TableContainer sx={{p:2}}>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>VOCI NOTE SPESE STAFF</TableCell>
                                <TableCell>RIMBORSO</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.voce}>
                                    <TableCell component="th" scope="row">
                                        {row.voce}
                                    </TableCell>
                                    <TableCell align="center">{row.rimborso}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack sx={{width:'100%', px:2, pb:2}} direction={"row"} justifyContent={"end"}>
                    <Button variant={"contained"} onClick={() => setOpen(!open)}>Chiudi</Button>
                </Stack>
            </Dialog>
            {/*step === 5 && <Box>
                <Button startIcon={<Info/>} onClick={() => setOpen(!open)}>
                    dettaglio rimborsi
                </Button>
            </Box>}
            {contenuto.infoBottom.length > 0 && contenuto.infoBottom.map(i => <Typography sx={{py:0.5}} variant={"body2"}>{i}</Typography>)*/}
            <Box sx={{pt:0.5,pb:2.5}}>
                {contenuto.titolo && <Box>
                    <Typography variant={"h6"} sx={{borderBottom: '1px solid #F9233B'}}>
                        {contenuto.titolo}
                    </Typography>
                </Box>}
                <Grid container spacing={2} sx={{pt: 0.5}}>
                    {contenuto.campi.map((c, i) => {
                        if (dati.campi[c.id]!== '' && dati.campi[c.id]!== null) {
                            return <ContentSezione recap={recap} handleSetDati={handleSetDati} campo={c} key={i}
                                                   dati={dati}/>
                        } else {
                            return <></>
                        }
                        })
                    }
                </Grid>
            </Box>
        </Box>
    )
}
