import {createTheme, responsiveFontSizes} from "@mui/material";

let adeccoTheme = createTheme({
    components:{
      MuiSelect:{
          styleOverrides:{
              root:{
                  '&.Mui-disabled':{
                      display:'none'
                  },
              }
          }
      }
    },
    palette:{
        mode:'dark',
        background:{
            default: '#1B1F27'
        },
        primary:{
            main: '#F9233B'
        },
        divider:'#F9233B'
    }
})

adeccoTheme = responsiveFontSizes(adeccoTheme)

export default adeccoTheme
