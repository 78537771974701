import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import header from '../assets/logo-winston.png'

export const WithHeader = ({children}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up("sm"))
    return (
        <Box>
            <Box sx={{width:'min(100%, 30rem)', margin:'auto', px:2, py:20}}>
                <img src={header} style={{width:'100%'}}/>
            </Box>
            {children}
        </Box>
    )
}
