import React from "react";
import {
    Box,
    Checkbox,
    FormControl, FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {DatePicker, DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

export const ContentSezione = ({campo, dati, handleSetDati,recap}) => {
  return(
      <Grid item xs={12} md={campo.type === 'checkbox' ? 12 : 6}>
          {(() =>{
              switch (campo.type){
                  case 'checkbox':
                      return <FormControl fullWidth>
                              <FormGroup>
                                  <FormControlLabel
                                      control={<Checkbox disabled={recap}
                                      checked={dati.campi[campo.id]}
                                      onChange={(e) => handleSetDati(campo.id, e.target.checked)}
                                      />} label={<div>Preso atto <a href={'#'} target={"_blank"} style={{color:'#F9233B'}}>dell’informativa privacy del titolare del trattamento</a>, presto il consenso al trattamento dei miei dati personali – eventualmente anche particolari - per gli scopi e con le modalità indicate.</div>}/>
                              </FormGroup>
                          </FormControl>
                  case 'select':
                      return recap ? <TextField disabled={recap} onChange={(e) => handleSetDati(campo.id, e.target.value)} type={campo.type} label={campo.title} variant={"standard"} sx={{width:'100%'}}/> : <FormControl variant="standard" fullWidth>
                          {!(campo.condizione && dati.campi[campo.condizione.id]!== campo.condizione.value) && <InputLabel>{campo.title}</InputLabel>}
                          <Select
                              disabled={campo.condizione && dati.campi[campo.condizione.id]!== campo.condizione.value} required={campo.required} onChange={(e) => handleSetDati(campo.id, e.target.value)}
                              label={campo.title}
                          >
                              {campo.values.map(v =>
                                  <MenuItem value={v} key={v}>{v}</MenuItem>
                              )}
                          </Select>
                      </FormControl>
                  case 'date':
                      return <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                              disabled={recap}
                              inputFormat={'DD/MM/YYYY'}
                              label={campo.title}
                              onChange={(e) => handleSetDati(campo.id, new Date(e))}
                              renderInput={(params) => <TextField disabled={recap} sx={{width:'100%'}} variant={'standard'} {...params} />}
                          />
                      </LocalizationProvider>
                  default:
                      return <TextField disabled={recap} onChange={(e) => handleSetDati(campo.id, e.target.value)} type={campo.type} label={campo.title} variant={"standard"} sx={{width:'100%'}}/>
              }
          })()}
      </Grid>
  )
}
