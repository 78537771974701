import './App.css';
import {CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {Registration} from "./pages/Registration";
import {Engagement} from "./pages/Engagement";
import {Trasporti} from "./pages/Trasporti";
import {Recap} from "./pages/Recap";
import {LiveStreaming} from "./pages/LiveStreaming";

function App() {
  return (
    <div>
      <CssBaseline/>
        <Routes>
            <Route path={'/'} element={<Recap/>}/>
        </Routes>
    </div>
  );
}

export default App;
