import React, {useEffect, useState} from "react";
import {WithHeader} from "../layouts/WithHeader";
import {Backdrop, Box, Button, Card, CardActions, CircularProgress, Container, Stack, Typography} from "@mui/material";
import {FormContent} from "../components/FormContent";
import {red} from "@mui/material/colors";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useParams} from "react-router-dom";
import {mockupData} from "../assets/data/mockupData";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const startData = [
    {
        step: 1,
        campi: {
            partecipo: '',
        },
    },
    {
        step: 2,
        campi: {
            nome: '',
            cognome: '',
            indirizzo: '',
            cap: '',
            provincia: '',
            citta: '',
            paese: '',
            luogoNascita: '',
            dataNascita: null,
            email: '',
            cellulare: '',
            notaCellulare: '',
            ragioneSociale: '',
            specificaRagioneSociale: ''
        },
    },
    {
        step: 3,
        campi: {
            pernottamento: ''
        }
    },
    {
        step: '3A',
        campi: {
            tipoDocumento: '',
            numeroDocumento: '',
            rilasciatoIl: null,
            rilasciatoDa: '',
            scadenza: null,
        }
    },
    {
        step: '3B',
        campi: {
            nomeCompagno: '',
            cognomeCompagno: ''
        }
    },
    {
        step: 4,
        campi: {
            mezzoDiTrasporto: ''
        }
    },
    {
        step: 5,
        campi: {
            privacy: false
        }
    }
]

export const Registration = ({recap}) => {
    const {hash} = useParams()
    const [step, setStep] = useState(0)
    const [dati, setDati] = useState(startData)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [postFailed, setPostFailed] = useState(false)
    const [sending, setSending] = useState(false)
    const [dirittoPernottamento, setDirittoPernottamento] = useState(false)
    const [open, setOpen] = useState(true)

    useEffect(() => {
        getDati()
    }, [])

    const getDati = async () => {
        setLoading(true)
        const r = mockupData
        startData.forEach( d =>
            {
                Object.keys(d.campi).forEach(c => {
                    d.campi[c] = r.user[c] ?? d.campi[c]
                })
            }
        )
        setDirittoPernottamento(r.user.diritto_pernottamento)
        setDati(startData)
        setLoading(false)
    }

    const handlePrev = () => {
        if (step === 5 && dati[2].campi.pernottamento !== 'Usufruirò del pernottamento') {
            setStep(2)
        }else if(step === 6 && dati[0].campi.partecipo !== 'In presenza'){
            setStep(0)
        }
        else {
            setStep(step - 1)
        }
        setTimeout(() => document.getElementById("form").scrollIntoView(), 200)
    }

    const handleNext = () => {
        if (step === 2 && dati[2].campi.pernottamento !== 'Usufruirò del pernottamento') {
            setStep(5)
        }else if(step === 0 && dati[0].campi.partecipo !== 'In presenza'){
            setStep(6)
        }
        else {
            setStep(step + 1)
        }
        setTimeout(() => document.getElementById("form").scrollIntoView(), 200)
    }

    const sendDati = async (e) => {
        e.preventDefault()
        console.log(step)
        setSending(true)
        if (step === 6) {
            setSuccess(true)
        }
        else {
            handleNext()
        }
        setSending(false)
    }

    const handleSetDati = (id, value) => {
        let d = dati
        d[step].campi[id] = value
        setDati([...d])
    }

    return (
        <Box>
            <Snackbar open={postFailed} autoHideDuration={6000} onClose={() => setPostFailed(false)}>
                <Alert onClose={() => setPostFailed(false)} severity="error" sx={{width: '100%'}}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            {success ?
                <Typography variant={'h4'} fontWeight={'bold'} textAlign={'center'} sx={{pt:2}}>
                    Registrazione avvenuta con successo
                </Typography>
                :
                <Container maxWidth={'md'} sx={{pb: 3}}>

                {loading ?
                    <Box sx={{width: '100%', textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    <Box>
                        {error &&
                            <Container maxWidth={"sm"} sx={{pt: 2}}>
                                <Typography textAlign={"center"} variant={"h5"} fontWeight={'bold'} color={red[400]}>
                                    Il link è errato, si prega di riprovare
                                </Typography>
                            </Container>
                        }

                        {(!error && !recap) &&
                            <form style={{width: '100%'}} onSubmit={sendDati}>
                                <FormContent dirittoPernottamento={dirittoPernottamento} handleSetDati={handleSetDati}
                                             step={step} dati={dati[step]}/>
                                <Typography variant={"body1"} fontWeight={'bold'}>
                                    TERMINE ULTIMO REGISTRAZIONE ORE 15 DEL 28 NOVEMBRE
                                </Typography>
                                {sending ?
                                    <Stack direction={"row"} justifyContent={"end"}>
                                        <CircularProgress/>
                                    </Stack>
                                    :
                                    <Stack sx={{width: '100%'}} justifyContent={"end"} spacing={2} direction={'row'}>
                                        <Button onClick={handlePrev} disabled={step === 0}>
                                            Indietro
                                        </Button>
                                        <Button disabled={step === 6 && dati[6].campi.privacy === false} type={"submit"}
                                                variant={"contained"}>
                                            {step === 6 ? 'Concludi registrazione' : 'Avanti'}
                                        </Button>
                                    </Stack>}
                            </form>
                        }
                        {recap &&
                            <Box>
                                <Typography variant={"h5"} fontWeight={"bold"}>
                                    Consulta qui sotto i dati inseriti in fase di registrazione
                                </Typography>
                                {[...Array(6).keys()].map(step =>
                                    <FormContent
                                        recap={recap}
                                        dirittoPernottamento={dirittoPernottamento} handleSetDati={handleSetDati}
                                        step={step} dati={dati[step]}/>
                                )}
                            </Box>
                        }
                    </Box>}
            </Container>}
        </Box>
    )
}
