import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {WithHeader} from "../layouts/WithHeader";
import {Box, Container, Typography} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Registration} from "./Registration";
import {Trasporti} from "./Trasporti";
import {Engagement} from "./Engagement";
import {ContattiSegreteria} from "../components/ContattiSegreteria";
import {RecapLogistica} from "./RecapLogistica";
import {RecapTema} from "./RecapTema";

export const Recap = () => {
    const {hash} = useParams()
    const [value, setValue] = React.useState('2');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <WithHeader>
            <Container maxWidth={'md'} sx={{pb: 3}}>
                <Typography variant={"h4"} textAlign={'center'} fontWeight={'bold'} color={'#F9233B'} sx={{py:2}}>
                    Form di registrazione
                </Typography>
                <Registration/>
            </Container>
        </WithHeader>
    )
}
