export const mockupData = {
    "user": {
        "logisticComplete": true,
        "luogoArrivo": "Milano Centrale",
        "pernottamento_singolo": false,
        "mezzoPersonale": "",
        "checkin": true,
        "luogoPartenza": "Torino",
        "numeroDocumento": "3245234",
        "updatedAt": {"_seconds": 1671207450, "_nanoseconds": 637000000},
        "citta": "Torino",
        "ragioneSociale": "ADECCO ITALIA S.P.A. - Adecco Workforce Solutions",
        "engagementCompletedAt": {"_seconds": 1669646828, "_nanoseconds": 298000000},
        "qrcodeUrl": "https://storage.googleapis.com/adecco-kickoff-2023_public/539ff428a5f68270d35901d05a1f7d74.png",
        "cellulare": "123456789",
        "dataNascita": "2022-12-30T23:00:00.000Z",
        "mezzoDiTrasportoChanged": false,
        "notaCellulare": "Privato",
        "cognome": "Tech",
        "pernottamento": "Usufruirò del pernottamento",
        "rilasciatoIl": "",
        "orarioCheckIn": "",
        "luogoArrivoRitorno": "",
        "area": "VIOLA",
        "paese": "Italia",
        "engagement": {
            "persone": "Al Capone",
            "viaggiare": "Un purosangue inglese",
            "stile": "Una camicia sgargiante",
            "musica": "Luna di Gianni Togni",
            "gruppo": "A un tour dei Rolling Stones"
        },
        "luogoPartenzaRitorno": "Milano Garibaldi",
        "hotel": "HOTEL ASSOLUTO",
        "tipoDocumento": "",
        "partecipo": "In presenza",
        "capoCamera": "PROVA4",
        "luogoNascita": "Torino",
        "complete": true,
        "numeroVolo": "",
        "specificaRagioneSociale": "Amazon e Logistica",
        "scadenza": "",
        "indirizzo": "Via Luisa del Carretto",
        "rilasciatoDa": "torino",
        "cognomeCompagno": "Ugolini",
        "engagementComplete": true,
        "nomeCompagno": "Marley",
        "parcheggio": false,
        "last_login": {"_seconds": 1673443019, "_nanoseconds": 422000000},
        "email": "hello@plesh.co",
        "logisticCompletedAt": {"_seconds": 1669883719, "_nanoseconds": 174000000},
        "note": "TEST",
        "mezzoDiTrasporto": "In treno",
        "provincia": "TO",
        "cap": "10122",
        "mezzoDiRitorno": "In treno",
        "diritto_pernottamento": true,
        "dataOraArrivo": "2022-01-08T00:00:00.000Z",
        "nome": "Plesh"
    }, "complete": true, "logisticComplete": true, "engagementComplete": true,

    /*"user": {
        "updatedAt": {"_seconds": 1671553967, "_nanoseconds": 268000000},
        "nome": "Stefano",
        "citta": "torino",
        "mezzoPersonale": "Auto privata",
        "paese": "italia",
        "luogoPartenzaRitorno": "",
        "email": "stefano.frea@pianob.it",
        "nomeCompagno": "Samantha",
        "rilasciatoDa": "torino",
        "diritto_pernottamento": true,
        "checkin": true,
        "logisticCompletedAt": {"_seconds": 1669971280, "_nanoseconds": 681000000},
        "mezzoDiRitorno": "Con la mia auto aziendale",
        "cellulare": "3384313480",
        "indirizzo": "via puccini 10",
        "specificaRagioneSociale": "Region Nord Ovest",
        "complete": true,
        "provincia": "torino",
        "hotel": "",
        "ragioneSociale": "ADECCO ITALIA S.P.A. - Adecco Workforce Solutions",
        "pernottamento": "Usufruirò del pernottamento",
        "engagementCompletedAt": {"_seconds": 1669971289, "_nanoseconds": 111000000},
        "qrcodeUrl": "https://storage.googleapis.com/adecco-kickoff-2023_public/c725fbdac7724b71d89955fff2fe2b47.png",
        "luogoPartenza": "",
        "logisticComplete": true,
        "parcheggio": "",
        "cognome": "Frea",
        "rilasciatoIl": "2022-11-12T23:00:00.000Z",
        "mezzoDiTrasporto": "Con il mio mezzo di trasporto privato",
        "engagementComplete": true,
        "luogoArrivo": "",
        "capoCamera": "PROVA",
        "partecipo": "In presenza",
        "engagement": {
            "viaggiare": "Un purosangue inglese",
            "persone": "Marty McFly",
            "stile": "Un cappello",
            "musica": "Summertime di Bessie Smith",
            "gruppo": "A una grande rapina di soldi del Monopoly"
        },
        "luogoArrivoRitorno": "",
        "dataNascita": "1980-07-19T07:57:42.000Z",
        "numeroVolo": "",
        "cap": "10128",
        "tipoDocumento": "Carta d'identità",
        "luogoNascita": "carmagnola",
        "dataOraArrivo": null,
        "cognomeCompagno": "Garofalo",
        "pernottamento_singolo": false,
        "scadenza": "2022-11-14T23:00:00.000Z",
        "note": "",
        "notaCellulare": "Privato",
        "last_login": {"_seconds": 1671131832, "_nanoseconds": 206000000},
        "mezzoDiTrasportoChanged": false
    }, "complete": true, "logisticComplete": true, "engagementComplete": true*/
}
